<template>
  <div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :showPagination="false"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text" style="padding-left: 10px;">
        {{ text }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 150px; max-width: 150px">
        {{ text }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-if="false">编辑</a>
          <a-divider type="vertical" v-if="false"/>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <edit-form
      ref="editModal"
      :visible="edit_visible"
      :loading="edit_ConfirmLoading"
      :model="edit_mdl"
      @cancel="handleEdit_Cancel"
      @ok="handleEditOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/a-tools/unit/modules/CreateForm'
import EditForm from '@/views/a-tools/unit/modules/EditForm'
import { common_unit_create, common_unit_delete, common_unit_list, common_unit_update } from '@/api/common'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      pageNum: 1,
      loading: false,
      confirmLoading: false,
      edit_ConfirmLoading: false,
      // 创建窗口控制
      visible: false,
      edit_visible: false,
      mdl: {},
      edit_mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '编号',
          dataIndex: 'code',
          width: 120,
          fixed: 'left',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '单位',
          width: 150,
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '组合',
          width: 150,
          dataIndex: 'show',
          ellipsis: true
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          dataIndex: 'action',
          width: 180,
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return common_unit_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            console.log(data)
            const datas = {
              entries: data
            }
            const unit = {}
            data.forEach(function (item, index, self) {
              unit[item.code] = item.name
            })
            this.$refreshDictionaries(unit, 'unit')
            return datas
          })
      }
    }
  },
  created () {
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      const page = sessionStorage.getItem('CurrentPage')
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        }
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit_Cancel () {
      this.edit_visible = false
      const form = this.$refs.editModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$emit('onEdit', record)
    },
    handleDelete (record) {
      console.log(record.id)
      common_unit_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          // values.tariff_rate = (values.tariff_rate / 100).toFixed(2)
          values.show = values.name + '-' + values.code
          console.log('提交的数据', values)
          common_unit_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleEditOk () {
      const form = this.$refs.editModal.form
      this.edit_ConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('提交的数据', values)
          common_unit_update(values, this.edit_mdl.id).then(res => {
            this.edit_visible = false
            this.edit_ConfirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((err) => {
              console.log(err)
              this.edit_ConfirmLoading = false
            })
        } else {
          this.edit_ConfirmLoading = false
        }
      })
    }
  }
}
</script>
